<template>
  <b-performance-table
    :data="rows"
    :custom-headers="headers"
    :loading="loading"
    :show-diff="dates.compareWith"
    :data-grouped-by="groupBy"
  />
</template>

<script>
import { get } from 'vuex-pathify';
import fetchWindowTypeDataMixin from '@/components/mixins/fetch-window-type-data-mixin';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import performanceDataMixin from '~/components/mixins/performance-data-mixin';
import { checkUserHasAiAssistant } from '~/util/utility-functions';

export default {
  name: 'DashDetailPublishersPerformanceList',
  components: {
    BPerformanceTable: () =>
      import(
        /* webpackChunkName: "b-performance-table" */ '~/components/elements/b-performance-table.vue'
      ),
  },

  mixins: [performanceDataMixin, fetchWindowTypeDataMixin],

  data() {
    return {
      loading: false,
      groupBy: 'publisher',
      userHasAiAssistant: false,
    };
  },
  computed: {
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    headers() {
      return [{ name: 'Name', sortKey: 'name' }, this.getCommonHeader()];
    },
    performance: get('dashboard/performance@publisher'),
    rows() {
      if (!this.performance) {
        return [];
      }

      const rows = this.performance.map((item) => {
        const rowItem = this.transformToRowItem(item);
        rowItem.name = item.displayName || item.name;
        rowItem.imgSrc =
          item.imageUrl ||
          'https://storage.googleapis.com/flip-assets/publisher_logos/color/otherhub_80x80.png';
        rowItem.nameColumnStyle = 'both';
        rowItem.subText = item.streamingCategory;
        return rowItem;
      });

      return this.sortRows(rows);
    },
    currentUser: get('common/currentUser'),
  },
  async mounted() {
    try {
      this.userHasAiAssistant = checkUserHasAiAssistant(this.currentUser);
      if (!this.userHasAiAssistant) {
        await this.loadPerformancePublisherData(this.payload);
      } else if (EventBus.performanceAPILoadedOnAIEnabled === false) {
        this.loadAllPerformanceData(this.payload);
      }
    } catch (err) {
      console.error('error mounting DashDetailPublishersPerformanceList ->', err);
    }
  },
  methods: {
    async loadPerformanceData(payload) {
      if (!this.userHasAiAssistant) {
        await this.loadPerformancePublisherData(payload);
      } else {
        this.loadAllPerformanceData(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-bg.zero {
  margin-top: 0 !important;
  margin-left: -10px !important;
}
</style>
